import React from "react";
import { ThemeProvider, createTheme } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
// import GoogleIcon from "@material-ui/icons";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import withFirebaseAuth from "react-with-firebase-auth";
import * as firebase from "firebase/app";
import "firebase/auth";
import firebaseConfig from "./firebaseConfig";
import BingoCard from "./BingoCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "@material-ui/core";
import { ReactComponent as VoxMedia } from "./voxmedia.svg";

require("firebase/functions");
const firebaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAppAuth = firebaseApp.auth();
//firebase.functions().useFunctionsEmulator("http://localhost:5001");
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

let theme = createTheme({
  typography: {
    fontFamily: "Montserrat",
  },
});

theme.typography.h3 = {
  fontWeight: "bold",
  fontSize: "3rem",

  [theme.breakpoints.up("md")]: {
    fontSize: "4rem",
  },
};

const styles = (theme) => ({
  root: {
    fontFamily: "Montserrat",
    //backgroundColor: "#fffdd8",
    height: "100%",
    //position: "fixed"
  },
  spinner: {
    color: "black",
  },
  title: {
    fontFamily: "Montserrat",
  },
  email: {
    fontFamily: "Montserrat",
    fontSize: "0.8em",
    lineHeight: "25px",
    [theme.breakpoints.up("md")]: {
      fontSize: "1em",
    },
  },
});

function SignIn(props) {
  console.log(props);
  return (
    <div>
      <Box
        // sx={{ mr: -2 }}
        style={{
          textAlign: "center",
          width: "100%",
        }}
      >
        <VoxMedia height="25px" />

        <Typography
          variant="h3"
          align="center"
          style={{
            letterSpacing: "0.75em",
            marginRight: "-0.75em",
            marginTop: "16px",
            marginBottom: "20px",
          }}
        >
          BINGO
        </Typography>
      </Box>
      <Grid item container justify="center">
        <Button
          onClick={props.auth}
          variant="contained"
          color="primary"
          style={{ textTransform: "none" }}
        >
          Sign In With Google
        </Button>
      </Grid>
    </div>
  );
}

class Bingo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      card: {},
      cardLoaded: false,
    };
  }

  componentDidMount() {
    firebaseAppAuth.onAuthStateChanged((user) => {
      if (user) {
        let retrieveCard = firebase.functions().httpsCallable("retrieveCard"),
          generateCard = firebase.functions().httpsCallable("generateCard");

        retrieveCard({}).then((result) => {
          if (result.data) {
            this.setState({ card: result.data, cardLoaded: true });
          } else {
            generateCard({}).then((result) => {
              this.setState({ card: result.data, cardLoaded: true });
            });
          }
        });
      }
    });
  }

  selectSquare(columnName, numberValue) {
    let saveCard = firebase.functions().httpsCallable("saveCard");
    let column = this.state.card[columnName];

    let squareIndex = column.findIndex((value, index) => {
      return value.value === numberValue;
    });

    if (squareIndex >= 0) {
      column[squareIndex].selected = !column[squareIndex].selected;
      this.setState({ column }, () => {
        saveCard({ card: this.state.card }).then((result) => {
          console.log("true");
        });
      });
    }
  }

  render() {
    let content,
      classes = this.props.classes;

    if (this.props.user === null) {
      content = (
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={12}>
            <SignIn
              auth={this.props.signInWithGoogle}
              theme={this.props.theme}
            ></SignIn>
          </Grid>
        </Grid>
      );
    } else if (this.props.user && this.state.cardLoaded) {
      content = (
        <Grid container justify="space-evenly" direction="column">
          <Grid container wrap="nowrap" justify="space-between">
            <Grid
              item
              s={1}
              style={{ padding: "1em" }}
              direction="column"
              justify="space-around"
            >
              <VoxMedia height="25px" />
            </Grid>
            <Grid s={1} style={{ padding: "1em" }}>
              <Typography className={classes.email}>
                {this.props.user.email}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            item
            className={classes.root}
            style={{ margin: "auto" }}
          >
            <BingoCard
              card={this.state.card}
              selectSquare={(column, value) => this.selectSquare(column, value)}
            ></BingoCard>
          </Grid>
        </Grid>
      );
    } else {
      content = (
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
        >
          <CircularProgress size="150px" className={classes.spinner} />
        </Grid>
      );
    }
    return (
      <ThemeProvider theme={theme}>
        <div>{content}</div>
      </ThemeProvider>
    );
  }
}

export default withFirebaseAuth({ providers, firebaseAppAuth })(
  withStyles(styles)(Bingo)
);
