import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Typography, withStyles } from "@material-ui/core";

const styles = (theme) => ({
  typography: {
    fontFamily: "Montserrat",
  },
  bingo: {
    height: "8vw",
    width: "8vw",
    minHeight: "50px",
    minWidth: "50px",
    border: ".4px solid gray",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
    backgroundColor: (props) => (props.selected ? randomColor() : "inherit"),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

let color = null;

function randomColor() {
  if (!color) {
    let colors = ["#7337D5", "#05A3F2", "#FF2B2B", "#FD82B9", "#EEDD00"];
    color = colors[Math.floor(Math.random() * colors.length)];
  }
  return color;
}

class BingoBlock extends Component {
  render() {
    const { column, classes, value } = this.props;

    return (
      <Grid key={value.value} item>
        <Paper
          square={true}
          className={classes.bingo}
          onClick={(e) => this.props.selectSquare(column, value)}
        >
          <Typography
            className={classes.typography}
            style={{ fontSize: "3vw" }}
            fontFamily="Montserrat"
          >
            {value}
          </Typography>
        </Paper>
      </Grid>
    );
  }
}

export default withStyles(styles)(BingoBlock);
