import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import BingoBlock from "./BingoBlock";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    fontFamily: "Montserrat",
    paddingTop: "1em",
  },
  typography: {
    fontFamily: "Montserrat",
  },
  button: {
    height: 100,
    width: 100,
  },
});

export class BingoCard extends Component {
  render() {
    const { classes, card } = this.props;

    return (
      <Grid item>
        <Grid
          container
          direction="row"
          justify="center"
          alignContent="stretch"
          className={classes.root}
          spacing={0}
        >
          {["b", "i", "n", "g", "o"].map((key) => (
            <Grid key={key} item>
              <Typography
                align="center"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "bold",
                  fontSize: "5vw",
                }}
              >
                {key.toUpperCase()}
              </Typography>
              <Grid container direction="column" justify="center" spacing={0}>
                {card[key].map((value) => (
                  <BingoBlock
                    column={key}
                    value={value.value}
                    selected={value.selected}
                    selectSquare={(c, v) => this.props.selectSquare(c, v)}
                  />
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(BingoCard);
