const firebaseConfig = {
  apiKey: "AIzaSyAoAeLaNMTS__MxXTLYi-VqYEhaRdMSXRU",
  authDomain: "g9-ken-bingo.firebaseapp.com",
  databaseURL: "https://g9-ken-bingo.firebaseio.com",
  projectId: "g9-ken-bingo",
  storageBucket: "g9-ken-bingo.appspot.com",
  messagingSenderId: "177958665185",
  appId: "1:177958665185:web:158ebe53223fad8075f0a0"
};

export default firebaseConfig;